import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import SimpleDialog from './components/SimpleDialog';
import SimpleSnackbar from './components/SimpleSnackbar';

const _SimpleDialog = Vue.extend( SimpleDialog );
const _SimpleSnackbar = Vue.extend( SimpleSnackbar );
const defaults = {
	acceptText : "OK",
	cancelText : "Cancel",
	hideCancel: false,
	hideAccept: false,
	allCaps: false,
	acceptColor: "primary",
	cancelColor: "secondary",
	closeText : '<i class="fas fa-times-circle" aria-hidden="true"><span class="sr-only">OK</span></i>',
	promptType: "text"
};
/**
 * Show a dialog of type "alert", "confirm", or "prompt." Returned promise is resolved with the dialog result when the
 * user dismisses or completes it. Message can be a string or an Object with the following properties:
 *
 * title : {string},
 * text : {string},
 * promptType: {string<"text","textarea","file">}
 * acceptText : {string}
 * cancelText : {string}
 *
 *
 * @param type {string<"alert","confirm","prompt">}
 * @param message {string|Object}
 * @returns {Promise<any>}
 * @private
 */
function _showDialog( type, message )
{
    return new Promise( ( resolve, reject ) =>
    {
        let dlog;
        new Promise( _resolve =>
        {
            if( typeof message === "string" )
            {
                message = {
                    title : "",
                    text : message
                };
            }
            const _message = {};
            Object.assign( _message, defaults, message );
            dlog = new _SimpleDialog( {
				vuetify: new Vuetify(),
                propsData : {
                    type : type,
                    message : _message,
                    resolve : _resolve
                }
            } );
            dlog.$mount();
        } ).then( result =>
        {
            setTimeout( () => dlog.$destroy, 300 );
            if( result === false )
            {
                reject();
            }
            else
            {
                resolve( result );
            }
        } );
    } );
}

// cancel any open dialogs -- click the 'Cancel' button for prompts/confirms or the 'OK' button for alerts
function _cancelDialogs()
{
	$('.simple-dialog-dismiss-btn').click()
}

/**
 * Show a snackbar with the default color and message. If message is an Object, it controls the properties of the
 * snackbar:
 *
 * text : {string}
 * color : {string}
 * snackbarTimeout {integer}
 * snackbarX : {string<"left"|"right">}
 * snackbarY : {string<"top"|"bottom">}
 *
 * @param color
 * @param message
 * @private
 */
function _showSnackbar( color, message )
{
    if( typeof message === "string" )
    {
        message = {
            text : message
        }
    }
    const _message = {
        color : color
    };
    Object.assign( _message, defaults, message );
    const sbar = new _SimpleSnackbar( {
		vuetify: new Vuetify(),
        propsData : _message
    } );
    const dNode = document.getElementById( defaults.snackbarParent ).appendChild( document.createElement( "div" ) );
    sbar.$mount( dNode );
    sbar.show();
    sbar.$on( "close", () =>
    {
        sbar.$destroy();
    } );
}

// cancel any open snackbars -- click the 'X' btn for snackbars
function _cancelSnackbars()
{
	$('.simple-dialog-snackbar-dismiss-btn').click()
}

/**
 * Plugin which provides the following methods for use in components:
 *
 * $alert( message ).then( result => handler )
 * $confirm( message ).then( result => handler )
 * $prompt( message ).then( result => handler )
 * $inform( message )
 * $warn( message )
 * $error( message )
 *
 * See DialogPromiseDemo in this module for full documentation.
 *
 * @type {{install(*, *=): void, name: string}}
 */
const DialogPromise = {
    name : "DialogPromise",
    /**
     * Options:
     * - snackbarParent : {string}, default "app" - ID of element under which snackbars will be mounted.
     * - locale : {string}, default "en" - Locale identifier
     *
     * @param Vue {Vue}
     * @param options {object}
     */
    install( Vue, options )
    {
        options = options || {};
        Object.assign( defaults, {
            snackbarX : "center",
            snackbarY : "bottom",
            snackbarTimeout : 5000,
            dialogMaxWidth : 500,
            snackbarParent : "app",
			color : "black",
        }, options );
        Vue.prototype.$alert = _showDialog.bind( this, "alert" );
        Vue.prototype.$confirm = _showDialog.bind( this, "confirm" );
        Vue.prototype.$prompt = _showDialog.bind( this, "prompt" );
        Vue.prototype.$inform = _showSnackbar.bind( this, "info" );
        Vue.prototype.$warn = _showSnackbar.bind( this, "warning" );
        Vue.prototype.$error = _showSnackbar.bind( this, "error" );

		Vue.prototype.$cancelDialogs = _cancelDialogs;
		Vue.prototype.$cancelSnackbars = _cancelSnackbars;
    }
};
export default DialogPromise;
