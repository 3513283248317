// vuex store for portal application
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// foo: {
		// 	get() { return this.$store.state.lst.foo },
		// 	set(val) { this.$store.commit('lst_set', ['foo', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			ml_models: '',
			normalize: false,
			snapshots: {},
			current_snapshot_title: '',
		},
		lst_prefix: 'sparklbot_test_harness_local_storage_setting_',
	},
	getters: {
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// anytime we alter an assignment or assignment result, trigger updates
			if (typeof o === 'object' && typeof o !== null && (o instanceof Assignment || o instanceof Assignment_Result)) {
				this.commit('trigger_collection_update')
			}

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else if (val == '*DELETE_FROM_STORE*') {
					// delete the val if it existed (if it didn't exist, we don't have to do anything)
					if (o[key] != undefined) Vue.delete(o, key)
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		replace_in_array(state, payload) {
			// this.$store.commit('replace_in_array', [array, old_val, new_val])
			let arr = payload[0]

			// try to find the index of the old_val; caller can send either a value to look for directly, or a property and a value
			let i, new_val
			if (payload.length == 3) {
				let old_val = payload[1]
				new_val = payload[2]
				i = arr.findIndex(x=>x==old_val)
			} else {
				let prop = payload[1]
				let old_val = payload[2]
				new_val = payload[3]
				i = arr.findIndex(x=>x[prop]==old_val)
			}

			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1, new_val)
			} else {
				// else push
				arr.push(new_val)
			}
		},

		splice_from_array(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let old_val = payload[1]

			// try to find the index of the old_val
			let i = arr.findIndex(x=>x==old_val)
			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1)
			}
		},

		splice_from_array_by_index(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let i = payload[1]

			arr.splice(i, 1)
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
				return
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

	},
	actions: {

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}
			U.ajax('sign_out', payload, result=>{
				// regardless of result, reload the page, which should show the google login button
				document.location.reload()
			});
		},

	}
})
