<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-app>
	<v-main id="app"><v-container>
		<div class="d-flex align-center">
			<h2>Sparkl-Bot Test Harness</h2>
			<v-spacer/>
			<!-- <v-btn small color="primary" @click="load_sample_responses">Load Sample Responses</v-btn> -->
			<v-select v-model="current_snapshot_title" :items="snapshot_select_items" label="Load Snapshot" dense outlined hide-details @change="restore_from_snapshot"></v-select>
			<v-btn v-if="results_loaded>-1" small color="pink darken-2" dark class="ml-4" @click="save_snapshot"><v-icon small class="mr-2">fas fa-save</v-icon>Save Snapshot</v-btn>
		</div>
		<div class="mt-2">
			<div class="mb-1">Model response(s):</div>
			<v-textarea outlined hide-details clearable auto-grow label="" v-model="model_responses_raw" class="k-max-height-textarea"></v-textarea>
		</div>
		<div class="mt-2">
			<div class="mb-1">Test response(s):</div>
			<v-textarea outlined hide-details clearable auto-grow label="" v-model="test_responses_raw" class="k-max-height-textarea"></v-textarea>
		</div>
		<div class="mt-3 d-flex align-center">
			<div><nobr>
				<v-checkbox class="shrink mt-0 pt-0 mr-4 d-inline-block" hide-details label="USE" v-model="ml_models_use"></v-checkbox>
				<v-checkbox class="shrink mt-0 pt-0 mr-4 d-inline-block" hide-details label="BERT-base" v-model="ml_models_bert"></v-checkbox>
				<v-checkbox disabled class="shrink mt-0 pt-0 mr-4 d-inline-block" hide-details label="RoBERTa" v-model="ml_models_roberta"></v-checkbox>
			</nobr></div>

			<v-checkbox class="shrink mt-0 pt-0 mr-3 ml-8 d-inline-block" hide-details label="Normalize strings" v-model="normalize"></v-checkbox>

			<div style="flex:1 0 auto"><v-btn large color="primary" block @click="run"><v-icon small class="mr-2">fas fa-person-running</v-icon>Run</v-btn></div>
		</div>
		<div class="mt-3" v-if="results_loaded>=0">

			<div class="mb-3">Execution time: <span v-for="(time, ml_model) in execution_time" class="px-3"><b>{{ml_model}}:</b> {{time.toFixed(3)}} sec</span></div>

			<!-- multiple-model table -->
			<v-data-table v-if="multiple_response_table_rows" hide-default-footer dense :items="multiple_response_table_rows" :items-per-page="-1">
				<template v-slot:header><thead><tr>
					<th @click="sort_by='initial'" style="cursor:pointer">Model ID</th>
					<th>Model Response</th>
					<th v-for="(ml_model) in ml_models" class="text-center" @click="sort_by='ss'+ml_model"><nobr :style="sort_by=='ss'+ml_model?'color:#000':''">{{ml_model}}: SS<v-icon small class="ml-1" v-if="sort_by=='ss'+ml_model">fas fa-caret-down</v-icon></nobr></th>
					<th v-for="(ml_model) in ml_models" class="text-center" @click="sort_by='ms'+ml_model"><nobr :style="sort_by=='ms'+ml_model?'color:#000':''">{{ml_model}}: MS<v-icon small class="ml-1" v-if="sort_by=='ms'+ml_model">fas fa-caret-down</v-icon></nobr></th>
				</tr></thead></template>
				<template v-slot:item="{ item }">
					<tr :style="item.model_response_index!=null&&model_response_for_single_model_table==item.model_response_index?'background-color:#ff9':''">
						<td style="white-space:nowrap" :style="item.model_string=='MEANS'?'border-bottom:2px solid black':''"><span v-if="item.model_response_index!=null">{{item.model_response_index+1}}. </span><b>{{item.model_id}}</b> [{{item.model_avgs[0]?item.model_avgs[0].n_matches:'–'}}]</td>
						<td :style="item.model_string=='MEANS'?'border-bottom:2px solid black':''" style="font-size:10px; line-height:12px; white-space:nowrap; max-width:400px; overflow:auto;"><v-icon small class="mr-2" color="primary" @click="show_single_result(item)">fas fa-bolt</v-icon>{{item.model_string}}</td>
						<td style="white-space:nowrap" :style="(item.model_string=='MEANS'?'border-bottom:2px solid black;':'')+cell_shade(item,index)" v-for="(ml_model, index) in ml_models" class="text-center"><v-icon v-if="!item.model_avgs[index] || !item.model_avgs[index].match_avg" small color="#fff">fas fa-asterisk fa-spin</v-icon>{{item.model_avgs[index]?item.model_avgs[index].simscore_avg:''}}</td>
						<td style="white-space:nowrap" :style="(item.model_string=='MEANS'?'border-bottom:2px solid black;':'')+(item.best_models&&item.best_models.includes(ml_model)?'font-weight:bold;background-color:#ccf;':'')" v-for="(ml_model, index) in ml_models" class="text-center"><v-icon v-if="!item.model_avgs[index] || !item.model_avgs[index].match_avg" small color="#000">fas fa-asterisk fa-spin</v-icon>{{(!item.model_avgs[index] || !item.model_avgs[index].match_avg)?'':`${item.model_avgs[index].match_avg} (${item.model_avgs[index].scaled_match_avg})`}}</td>
					</tr>
				</template>
			</v-data-table>

			<!-- single-model table -->
			<div v-if="single_model_table_rows">
				<div v-if="model_response_for_single_model_table!=null" style="margin-bottom:12px; margin-top:20px;padding-top:20px;border-top:4px solid #000"><b>Results for model response {{model_response_for_single_model_table+1}}:</b> {{model_responses.strings[model_response_for_single_model_table]}}</div>
				<v-data-table hide-default-footer dense :items="single_model_table_rows" :items-per-page="-1">
					<template v-slot:header><thead><tr>
						<th @click="sort_by='initial'" style="cursor:pointer">Test ID</th>
						<th>Test Response</th>
						<th class="text-center" v-for="(header, index) in table_headers" v-if="index>1" @click="sort_by=index" style="cursor:pointer"><nobr :style="sort_by==index?'color:#000':''">
							{{header.text}}<v-icon small class="ml-1" v-if="sort_by==index">fas fa-caret-down</v-icon>
							<div v-if="match_avgs[index-2]">{{match_avgs[index-2]}}</div>
						</nobr></th>
					</tr></thead></template>
					<template v-slot:item="{ item }">
						<tr>
							<td>{{item.index}}. <b>{{item.test_id}}</b> <v-icon v-if="item.is_match" color="pink darken-2" small class="ml-1">fas fa-asterisk</v-icon></td>
							<td style="font-size:10px; line-height:12px; white-space:nowrap; max-width:500px; overflow:auto;">{{item.test_string}}</td>
							<td v-for="(simscore, index) in item.simscores" :key="index" :style="cell_shade(simscore*1)" class="text-center"><v-icon v-if="simscore==-1" small color="#fff">fas fa-asterisk fa-spin</v-icon>{{(simscore==-1)?'':simscore}}</td>
						</tr>
					</template>
				</v-data-table>
			</div>

		</div>
	</v-container></v-main>
</v-app></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'App',
	components: { },
	data() { return {
		model_responses_raw: '',
		test_responses_raw: '',

		model_responses: null,
		test_responses: null,

		ml_models_use: false,
		ml_models_bert: false,
		ml_models_roberta: false,

		results: {},
		execution_time: {},

		results_loaded: -1,
		model_response_for_single_model_table: null,

		match_avgs: [],

		sort_by: 'initial',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		current_snapshot_title: {
			get() { return this.$store.state.lst.current_snapshot_title },
			set(val) { this.$store.commit('lst_set', ['current_snapshot_title', val]) }
		},
		snapshot_select_items() {
			let arr = []
			for (let title in this.$store.state.lst.snapshots) {
				arr.push({value: title, text: title})
			}
			return arr
		},
		ml_models: {
			get() { return this.$store.state.lst.ml_models.split(',') },
			set(val) { this.$store.commit('lst_set', ['ml_models', val.join(',')]) }
		},
		normalize: {
			get() { return this.$store.state.lst.normalize },
			set(val) { this.$store.commit('lst_set', ['normalize', val]) }
		},
		single_model_table_response_index() {
			if (this.results_loaded < 0) return null

			if (this.model_responses.strings.length == 1) return 0
			else if (this.model_response_for_single_model_table != null) return this.model_response_for_single_model_table
			return null
		},
		table_headers() {
			if (this.results_loaded < 0 || this.single_model_table_response_index == null) return []

			// we only use these for the one-model response table
			let arr = [
				{ text: 'Test ID', align: 'left', sortable: false, value:'test_id' },
				{ text: 'Test Response', align: 'left', sortable: false, value:'test_string' },
			]
			for (let ml_model of this.ml_models) {
				arr.push({text: ml_model + ': ' + this.model_responses.ids[this.single_model_table_response_index], align: 'center', sortable: false, value:this.single_model_table_response_index})
			}
			return arr
		},
		single_model_table_rows() {
			if (this.results_loaded < 0 || this.single_model_table_response_index == null) return null

			let rows = []
			for (let i = 0; i < this.test_responses.ids.length; ++i) {
				let row = {
					test_id: this.test_responses.ids[i],
					test_string: this.test_responses.strings[i],
					simscores: [],
				}

				// note if this test response is designated as a match of the model
				row.is_match = this.model_responses.matches[this.single_model_table_response_index] && this.model_responses.matches[this.single_model_table_response_index].includes(row.test_id)

				for (let ml_model of this.ml_models) {
					if (!this.results[ml_model] || !this.results[ml_model][this.single_model_table_response_index]) row.simscores.push(-1)
					else row.simscores.push(Math.round(this.results[ml_model][this.single_model_table_response_index][i] * 1000))
				}
				rows.push(row)
			}

			// compute match averages for each comparison string (if we have matches specified)
			this.match_avgs = []
			let index = 0
			for (let ml_model of this.ml_models) {
				let o = this.compute_match_avg(this.single_model_table_response_index, ml_model)
				this.match_avgs[index] = (o.match_avg) ? o.match_avg + ' / ' + o.simscore_avg : ''
				++index
			}

			if (this.sort_by != 'initial' && !isNaN(this.sort_by*1)) {
				rows.sort((a,b) => b.simscores[this.sort_by-2] - a.simscores[this.sort_by-2])
			}
			for (let i = 0; i < rows.length; ++i) rows[i].index = i+1

			return rows
		},

		multiple_response_table_rows() {
			if (this.results_loaded < 0) return null
			if (this.model_responses.strings.length == 1) return null

			let rows = []
			rows.push({
				model_id: '',
				model_string: 'MEANS',
				model_avgs: [],
				best_models: [],
				model_response_index: null,
			})
			for (let j = 0; j < this.model_responses.strings.length; ++j) {
				let row = {
					model_id: this.model_responses.ids[j],
					model_string: this.model_responses.strings[j],
					model_avgs: [],
					best_models: [],
					model_response_index: j,
				}
				let ml_model_index = 0
				let best_model_match_avg = 1000
				for (let ml_model of this.ml_models) {
					let o = this.compute_match_avg(j, ml_model)
					row.model_avgs.push(o)
					if (o.match_avg) {
						if (!rows[0].model_avgs[ml_model_index]) {
							rows[0].model_avgs[ml_model_index] = {simscore_avg: 0, match_avg: 0, scaled_match_avg: 0, n_matches:0}
						}
						rows[0].model_avgs[ml_model_index].match_avg += o.match_avg*1
						rows[0].model_avgs[ml_model_index].scaled_match_avg += o.scaled_match_avg*1
						rows[0].model_avgs[ml_model_index].simscore_avg += o.simscore_avg*1
						rows[0].model_avgs[ml_model_index].n_matches += o.n_matches*1
					}

					// find "best" model -- lowest match score, allowing for ties
					if (!o.match_avg) {
						// if any model doesn't have a value yet, we don't know what's best...
						row.best_models = []
						best_model_match_avg = -1
					} else if (o.match_avg*1 == best_model_match_avg) {
						row.best_models.push(ml_model)
					} else if (o.match_avg && o.match_avg*1 < best_model_match_avg) {
						best_model_match_avg = o.match_avg*1
						row.best_models = [ml_model]
					}
					
					++ml_model_index
				}
				rows.push(row)
			}

			// calculate overall avgs
			let best_model_match_avg = 1000
			for (let i = 0; i < this.ml_models.length; ++i) {
				if (!rows[0].model_avgs[i]) continue
				rows[0].model_avgs[i].match_avg = (rows[0].model_avgs[i].match_avg / this.model_responses.strings.length).toFixed(1)
				rows[0].model_avgs[i].scaled_match_avg = (rows[0].model_avgs[i].scaled_match_avg / this.model_responses.strings.length).toFixed(0)
				rows[0].model_avgs[i].simscore_avg = (rows[0].model_avgs[i].simscore_avg / this.model_responses.strings.length).toFixed(0)
				rows[0].model_avgs[i].n_matches = (rows[0].model_avgs[i].n_matches / this.model_responses.strings.length).toFixed(1)

				// get overall model champion
				if (rows[0].model_avgs[i].match_avg*1 == best_model_match_avg) {
					rows[0].best_models.push(this.ml_models[i])
				} else if (rows[0].model_avgs[i].match_avg*1 < best_model_match_avg) {
					best_model_match_avg = rows[0].model_avgs[i].match_avg*1
					rows[0].best_models = [this.ml_models[i]]
				}
			}

			return rows
		},
	},
	watch: {
	},
	created() {
		window.vapp = this
		this.$store.commit('lst_initialize')
		this.ml_models_use = this.ml_models.includes('use')
		this.ml_models_bert = this.ml_models.includes('bert')
		this.ml_models_roberta = this.ml_models.includes('roberta')

		this.initialize_samples()
	},
	mounted() {
	},
	methods: {
		initialize_samples() {
			if (this.current_snapshot_title) {
				this.restore_from_snapshot()
			} else if (!U.object_has_keys(this.$store.state.lst.snapshots)) {
				// if we don't have any snapshots, load one
				this.model_responses_raw = 'He finds the head of the pig, which Jack and the boys had killed, on a stick covered in flies. He calls it the Lord of the Flies.'
				this.test_responses_raw = $.trim(`
#8.3.1 While Simon is walking out in the forest, he encounters the pig that Jack and the other boys killed. The pig’s head is on a stick and it’s guts are covered in flies, so Simon gives the name “Lord of the Flies” to it.
#8.3.2 He finds the pig head with a stick on it. He calls it the Lord of the Flies because it has blood and flies all over it.
#8.3.3 he finds the pig head and it has a while bunvh of flies so he valls it the lord of the flies
#8.3.3b he finds the pig head and it has a whole bunch of flies so he calls it the lord of the flies
#8.3.4 He encounters the pigs head, he names it lord of the flies
#8.3.5 Simon encounters the Pig head on a stick with flies all around it. He misinterprets it to be the Lord of The Flies which is another name for satan. He thinks that this is the evil in all of them.
#8.3.6 While walking out in the forest, Simon encounters a glade where he is able to sit alone in the sunshine and meditate. The glade was described as "Beyond the screen of leaves the sunlight pelted down and the butterflies danced in the middle of their unending dance." A beautiful glade he calls, "beautiful land."
#8.3.7 Simon calls the pretty place they found a “beautiful land”
#8.3.8 He named it beautiful land because it was full of flowers and that just goes with Simons personality.
`)
				this.save_snapshot_finish('Sample responses (LOTF)')
				this.model_responses_raw = ''
				this.test_responses_raw = ''
			}
		},
		parse_strings(val, desc) {
			let raw_arr = val.split('\n')
			let ids = []
			let matches = []
			let strings = []
			let normalized_strings = []
			for (let i = 0; i < raw_arr.length; ++i) {
				let s = $.trim(raw_arr[i])
				if (!s) continue

				// extract id if there (e.g. `#8.3.1 `; otherwise use index as id
				let id = 'R' + i
				if (s.search(/^#(.*?)\s+/) > -1) {
					s = s.replace(/^#(.*?)\s+/, '')
					id = RegExp.$1
				}

				let match
				if (s.search(/^\[(.*?)\]\s+/) > -1) {
					s = s.replace(/^\[(.*?)\]\s+/, '')
					match = RegExp.$1
					match = match.split(/\s*,\s*/)
				}
				// if we get matches and we're doing model responses, only include matches that are included in the test responses
				if (desc == 'model' && match && match.length > 0) {
					for (let i = match.length-1; i >= 0; --i) {
						if (!this.test_responses.ids.includes(match[i])) {
							match.splice(i, 1)
						}
					}
				}

				ids.push(id)
				matches.push(match)
				strings.push(s)
				normalized_strings.push(U.normalize_string_for_validation(s))
			}

			if (strings.length == 0) {
				this.$alert(`No ${desc} strings specified`)
				return null
			}

			return {
				ids: ids,
				matches: matches,
				strings: strings,
				normalized_strings: normalized_strings
			}
		},

		run() {
			// this.run_altogether(); return

			this.test_responses = this.parse_strings(this.test_responses_raw, 'test')
			if (this.test_responses) this.model_responses = this.parse_strings(this.model_responses_raw, 'model')
			if (!this.model_responses) return

			let ma = []
			if (this.ml_models_use) ma.push('use')
			if (this.ml_models_bert) ma.push('bert')
			if (this.ml_models_roberta) ma.push('roberta')
			this.ml_models = ma
			if (this.ml_models.length == 0) {
				this.$alert('No ML models checked.')
				return
			}
			
			this.results_loaded = -1
			this.results = {}
			this.execution_time = {}

			U.loading_start()
			for (let i = 0; i < this.model_responses.strings.length; ++i) {
				let payload
				if (this.normalize) {
					payload = {
						models: JSON.stringify([this.model_responses.normalized_strings[i]]),
						tests: JSON.stringify(this.test_responses.normalized_strings),
					} 
				} else {
					payload = {
						models: JSON.stringify([this.model_responses.strings[i]]),
						tests: JSON.stringify(this.test_responses.strings),
					}
				}

				for (let ml_model of this.ml_models) {
					if (ml_model == 'use') {
						payload.sparkl_ml_ip = 'http://3.17.122.250'
						payload.sparkl_ml_path = 'similarity_use_matrix'
					} else if (ml_model == 'bert') {
						payload.sparkl_ml_ip = 'http://3.135.125.176/'
						payload.sparkl_ml_path = 'similarity_bert_base_matrix'
						// payload.sparkl_ml_path = 'similarity_bert_base_test'
					}

					U.ajax('sparkl_bot_test_harness', payload, result=>{
						if (result.status != 'ok') {
							this.$alert('Error in ajax call (possible timeout)')
							U.loading_stop()
							return
						}

						if (empty(this.results[ml_model])) {
							this.results[ml_model] = []
							this.execution_time[ml_model] = 0
						}
						this.results[ml_model][i] = result.sim_scores[0]
						this.execution_time[ml_model] += result.execution_time

						// increment results_loaded to start showing results
						++this.results_loaded
						U.loading_stop()
					})
				}
			}
		},

		run_altogether() {
			this.test_responses = this.parse_strings(this.test_responses_raw, 'test')
			if (this.test_responses) this.model_responses = this.parse_strings(this.model_responses_raw, 'model')
			if (!this.model_responses) return

			let ma = []
			if (this.ml_models_use) ma.push('use')
			if (this.ml_models_bert) ma.push('bert')
			if (this.ml_models_roberta) ma.push('roberta')
			this.ml_models = ma
			if (this.ml_models.length == 0) {
				this.$alert('No ML models checked.')
				return
			}
			
			let payload = {
				models: JSON.stringify(this.model_responses.normalized_strings),
				tests: JSON.stringify(this.test_responses.normalized_strings),
			}

			this.results_loaded = -1
			this.results = {}

			U.loading_start()
			for (let ml_model of this.ml_models) {
				if (ml_model == 'use') {
					payload.sparkl_ml_ip = 'http://3.17.122.250'
					payload.sparkl_ml_path = 'similarity_use_matrix'
				} else if (ml_model == 'bert') {
					payload.sparkl_ml_ip = 'http://3.135.125.176/'
					payload.sparkl_ml_path = 'similarity_bert_base_matrix'
					// payload.sparkl_ml_path = 'similarity_bert_base_test'
				}

				U.ajax('sparkl_bot_test_harness', payload, result=>{
					if (result.status != 'ok') {
						this.$alert('Error in ajax call (possible timeout)')
						this.loading_stop()
						return
					}

					this.results[ml_model] = result.sim_scores
					this.execution_time[ml_model] = result.execution_time

					// increment results_loaded to start showing results
					++this.results_loaded
					U.loading_stop()
				})
			}
		},

		compute_match_avg(mr_index, ml_model) {
			// if we don't have any matches recorded, return null
			if (!this.model_responses.matches[mr_index] || !this.results[ml_model] || !this.results[ml_model][mr_index]) return {match_avg: null, scaled_match_avg: null, simscore_avg:null, n_matches: 0}

			// get array of simscores for this ml_model and this model response
			let n_test_responses = this.test_responses.ids.length
			let arr = []
			for (let i = 0; i < n_test_responses; ++i) {
				arr.push({
					test_id: this.test_responses.ids[i],
					simscore: Math.round(this.results[ml_model][mr_index][i]*1000)
				})
			}

			// sort by simscores descending
			arr.sort((a,b) => b.simscore - a.simscore)

			// compute a) sum of ranks of matched items, and b) sum of simscores of matched items
			let match_sum = 0
			let simscore_sum = 0
			for (let k = 0; k < arr.length; ++k) {
				if (this.model_responses.matches[mr_index].includes(arr[k].test_id)) {
					simscore_sum += arr[k].simscore

					// if we're matching to 3 items, any of the matches could be 1, 2, or 3
					// so compute each val as (rank - 2), min 1
					let val = (k+1) - (this.model_responses.matches[mr_index].length - 1)
					if (val < 1) val = 1
					match_sum += val
				}
			}

			let match_avg = (match_sum / this.model_responses.matches[mr_index].length)
			let scaled_match_avg = Math.round(( (n_test_responses/2) - (match_avg-1) ) / (n_test_responses/2) * 100)
			return {
				n_matches: this.model_responses.matches[mr_index].length,
				match_avg: match_avg.toFixed(1),
				scaled_match_avg: scaled_match_avg,
				simscore_avg: Math.round(simscore_sum / this.model_responses.matches[mr_index].length)
			}
		},

		cell_shade(row, index) {
			let val
			if (typeof(row) == 'number') {
				val = row
			} else {
				if (!row.model_avgs || !row.model_avgs[index]) return ''
				val = row.model_avgs[index].simscore_avg
			}
			let g = Math.round(255 * val / 1000)
			let f = (val > 500) ? '#000' : '#fff'
			return `background-color:rgb(0,${g},0); color:${f}; font-weight:bold;`
		},

		show_single_result(row) {
			this.model_response_for_single_model_table = row.model_response_index
		},

		save_snapshot() {
			this.$prompt({
				text: 'Enter a descriptor for this snapshot. If you re-enter the descriptor for an existing snapshot, the previous snapshot will be replaced.',
				initialValue: this.current_snapshot_title,
				acceptText: 'Save Snapshot',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				if (this.current_snapshot_title == title) {
					if (this.model_responses_raw != this.$store.state.lst.snapshots[this.current_snapshot_title].model_responses_raw || this.test_responses_raw != this.$store.state.lst.snapshots[this.current_snapshot_title].test_responses_raw) {
						this.$confirm({
							title: 'Are you a sure?',
							text: 'The current model and/or test responses differ from those saved in the snapshot with this description. Are you sure you want to overwrite this snapshot?',
							acceptText: 'Proceed',
						}).then(y => {
							this.save_snapshot_confirmed(title)
						}).catch(n=>{console.log(n)}).finally(f=>{})
						return
					}
				}
				this.save_snapshot_confirmed(title)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_snapshot_confirmed(title) {
			this.save_snapshot_finish(title)
			this.current_snapshot_title = title
			this.$inform('Snapshot saved.')
		},

		save_snapshot_finish(title) {
			let o = {
				model_responses_raw: this.model_responses_raw,
				test_responses_raw: this.test_responses_raw,
				ml_models: this.ml_models,
				normalize: this.normalize,
				results: this.results,
				execution_time: this.execution_time,
				match_avgs: this.match_avgs,
			}

			let s = extobj(this.$store.state.lst.snapshots)
			s[title] = o
			this.$store.commit('lst_set', ['snapshots', s])
		},

		restore_from_snapshot() {
			console.log('restore_from_snapshot: ' + this.current_snapshot_title)

			let o = this.$store.state.lst.snapshots[this.current_snapshot_title]
			if (!o) { console.log('ERROR!!!'); return; }

			console.log(extobj(o))

			this.model_responses_raw = o.model_responses_raw
			this.test_responses_raw = o.test_responses_raw
			this.ml_models = o.ml_models
			this.normalize = o.normalize
			this.results = o.results
			this.execution_time = o.execution_time
			this.match_avgs = o.match_avgs

			// restore model checkboxes
			this.ml_models_use = this.ml_models.includes('use')
			this.ml_models_bert = this.ml_models.includes('bert')
			this.ml_models_roberta = this.ml_models.includes('roberta')

			this.model_response_for_single_model_table = null

			// re-parse responses
			this.test_responses = this.parse_strings(this.test_responses_raw, 'test')
			this.model_responses = this.parse_strings(this.model_responses_raw, 'model')

			if (U.object_has_keys(this.results)) this.results_loaded = 0
		},
	}
}
</script>

<style lang="scss">
html {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

body {
	font-size:16px;
}

.v-application {
	font-family: $sans-serif-font;
}

.k-max-height-textarea textarea {
	max-height:40vh; 
	overflow:auto;
	white-space:nowrap;
	font-size:10px;
	line-height:12px;
}

th {
	white-space:nowrap;
}
</style>
